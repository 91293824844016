import { useEffect, useRef, useState } from "react";
import styled from "styled-components";

interface Props {
  text?: string;
  onClick?: () => void;
  isDone?: boolean;
  style?: React.CSSProperties;
}

export default function GradientButton({
  text = "변경하기",
  onClick = () => {},
  isDone = false,
  style,
}: Props) {
  const containerRef = useRef<HTMLDivElement>(null);
  const [width, setWidth] = useState<number | null>(null);

  useEffect(() => {
    if (containerRef.current) {
      setWidth(containerRef.current.offsetWidth);
    }
  }, []);

  return (
    <Container ref={containerRef}>
      <div className="blur" style={{ width }} />
      {isDone ? (
        <button onClick={onClick} style={{ width, ...style }}>
          <span>{text}</span>
        </button>
      ) : (
        <button className="disabled" style={{ width }}>
          <span>{text}</span>
        </button>
      )}
    </Container>
  );
}

const Container = styled.div`
  position: relative;
  bottom: 20px;
  width: 100%;
  height: 59px;

  .blur {
    position: absolute;
    bottom: 10px;
    height: 59px;
    background: #fff;
    filter: blur(5px);
  }

  button {
    position: absolute;
    bottom: 0px;
    left: 0;
    right: 0;
    padding: 12px 20px;
    background: var(--Blue1000, #1c84ff);
    border-radius: 10px;
    z-index: 10;

    span {
      color: var(--White, #fff);
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px; /* 150% */
      letter-spacing: -0.32px;
    }
  }

  .disabled {
    background: ${({ theme }) => theme.colors.blueGray100};

    cursor: not-allowed;

    span {
      color: ${({ theme }) => theme.colors.blueGray500};
    }
  }
`;
