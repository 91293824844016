import React, { useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { useGetInfluencersList, usePostContent } from "@hooks/admin";
import B1TypeInputBoxTyping from "@components/core/BTypeInput/B1TypeInputBoxTyping";
import { InputTypeEnum } from "@components/core/BTypeInput/BTypeInput.interfaces";
import { Title } from "@components/styles";
import { useMenu } from "@features/useMenu";
import B1TypeInputBoxBtn from "@components/core/BTypeInput/B1TypeInputBoxBtn";
import B1TypeDateInput from "@components/core/input/B1TypeDateInput";
import { useNavigate } from "react-router-dom";
import { formatTime } from "@utils/date";

export default function AddContent() {
  const navigate = useNavigate();
  const { setCurrentMenu } = useMenu();

  useEffect(() => {
    setCurrentMenu("contents");
  }, []);

  const { data } = useGetInfluencersList();
  const { mutate } = usePostContent();

  const [contentInfo, setContentInfo] = useState({
    title: "",
    page_url: "",
    submitted_at: "",
    influencer_id: "",
  });

  const influencerList = useMemo(() => {
    if (!data) return [];

    const arr = data?.influencers.map((el) => {
      return { label: el.nickname, value: el._id };
    });

    return arr;
  }, [data]);

  const handlePost = () => {
    const date = new Date();

    const { influencer_id, ...content } = { ...contentInfo };
    const body = {
      ...content,
      submitted_at: `${content.submitted_at} ${formatTime(
        date.setHours(date.getHours() - 9)
      )}`,
    };

    mutate(
      { influencer_id, content: body },
      {
        onSuccess: (res) => {
          alert("제출 성공");
          navigate(-1);
        },
      }
    );
  };

  return (
    <Container>
      <Title>레포트 직접 제출</Title>
      <div style={{ height: 24 }} />
      <B1TypeInputBoxBtn
        inputType={InputTypeEnum.OnlyText}
        value={contentInfo.influencer_id}
        onChangeValue={(v: any) => {
          console.log(v);
          setContentInfo((prev) => ({ ...prev, influencer_id: v.value }));
        }}
        style={{ maxWidth: "30vw", minWidth: 670 }}
        label={"인플루언서"}
        placeholder="인플루언서를 선택하세요."
        listData={influencerList}
      />
      <B1TypeDateInput
        value={contentInfo.submitted_at}
        onChangeValue={(v: any) => {
          setContentInfo((prev) => ({
            ...prev,
            submitted_at: v,
          }));
        }}
        disabled={false}
        style={{ maxWidth: "30vw", minWidth: 670, zIndex: 10 }}
        label={"제출 날짜"}
        placeholder="제출 날짜를 선택하세요."
      />

      <B1TypeInputBoxTyping
        inputType={InputTypeEnum.OnlyText}
        label={"컨텐츠 제목"}
        placeholder={"컨텐츠 제목을 입력하세요."}
        value={contentInfo.title}
        onChangeValue={(v: InputTypeEnum.OnlyText) =>
          setContentInfo((prev) => ({ ...prev, title: v }))
        }
        style={{ maxWidth: "30vw", minWidth: 670 }}
      />
      <B1TypeInputBoxTyping
        inputType={InputTypeEnum.OnlyText}
        label={"노션 링크"}
        placeholder={"노션 링크를 입력하세요."}
        value={contentInfo.page_url}
        onChangeValue={(v: InputTypeEnum.OnlyText) =>
          setContentInfo((prev) => ({ ...prev, page_url: v }))
        }
        style={{ maxWidth: "30vw", minWidth: 670 }}
      />
      <div style={{ marginTop: 20, maxWidth: "30vw", minWidth: 670 }}>
        <Button $isDone={true} disabled={!true} onClick={handlePost}>
          등록하기
        </Button>
      </div>
    </Container>
  );
}

const Container = styled.div``;

const Button = styled.button<{ $isDone: boolean }>`
  padding: 10px 0;
  width: 100%;
  border-radius: 8px;
  border: none;
  background: ${({ theme, $isDone }) =>
    $isDone ? theme.colors.primaryBlue100 : theme.colors.blueGray100};
  cursor: pointer;
  color: ${({ theme, $isDone }) =>
    $isDone ? theme.colors.white : theme.colors.blueGray500};
  font-size: 18px;
  font-weight: 500;
  line-height: 28px; /* 155.556% */
  letter-spacing: -0.36px;

  &:disabled {
    cursor: not-allowed;
  }
`;
