import styled from "styled-components";

interface Props {
  desc?: string;
  onClick?: () => void;
  blue?: boolean;
}

export default function WebAlert({
  desc = "필수 값이 설정되지 않았습니다.",
  onClick,
  blue = false,
}: Props) {
  return (
    <Container>
      <Title>알림</Title>
      <Description>{desc}</Description>
      <Button onClick={onClick} $blue={blue}>
        <span>확인</span>
      </Button>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  width: 300px;
  padding: 24px 16px 20px 16px;
  flex-direction: column;
  align-items: center;
  border-radius: 20px;
  background: var(--White, #fff);
`;

const Title = styled.span`
  margin-bottom: 16px;
  color: ${({ theme }) => theme.colors.blueGray1000};
  text-align: center;
  font-size: 20px;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.4px;
`;

const Description = styled.span`
  color: ${({ theme }) => theme.colors.blueGray700};
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: -0.3px;
  text-align: center;
  white-space: pre-wrap;
`;

const Button = styled.button<{ $blue: boolean }>`
  margin-top: 32px;
  padding: 8px 20px;
  width: 100%;
  border-radius: 8px;
  background-color: ${({ theme, $blue }) =>
    $blue ? theme.colors.blue1000 : theme.colors.red1000};

  span {
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 150% */
    letter-spacing: -0.32px;
    color: ${({ theme }) => theme.colors.white};
  }
`;
