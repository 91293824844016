import { Outlet, useNavigate } from "react-router-dom";
import WebAlert from "@components/core/alert/WebAlert";
import { PopUpContainer } from "@components/core/popUp/PopupContainer";
import { useAlert } from "@features/useAlert";

export default function GlobalProvider() {
  const navigate = useNavigate();
  const { globalAlert, closeGlobalAlert } = useAlert();

  return (
    <>
      <Outlet />
      <PopUpContainer
        isVisible={globalAlert.isVisible}
        setIsVisible={closeGlobalAlert}
      >
        <WebAlert
          desc={globalAlert.desc}
          onClick={() => {
            closeGlobalAlert();
            globalAlert.path && navigate(globalAlert.path);
          }}
          blue={globalAlert.blue}
        />
      </PopUpContainer>
    </>
  );
}
