import styled from "styled-components";
import XIcon from "@assets/basic/XIcon";
import { useEffect, useState } from "react";
import { usePostContentPayments } from "@hooks/admin";
import { useNavigate } from "react-router-dom";
import EmptyPng from "../../../../../assets/icon/listCheck.png";
import { SubmitDataState } from "./AccountingInterfaces";

interface SubmitAccountingBoxProps {
  submitData: SubmitDataState[];
  setSubmitData: React.Dispatch<React.SetStateAction<SubmitDataState[]>>;
}

export default function SubmitAccountingBox({ submitData, setSubmitData }) {
  const navigate = useNavigate();
  const { mutate } = usePostContentPayments();

  const onSubmit = () => {
    if (submitData.length) {
      mutate(
        { payments: submitData },
        {
          onSuccess: () => {
            alert("정산이 완료되었습니다");
            navigate("/content/payments");
          },
        }
      );
    }
  };

  return (
    <Container>
      {/* <div style={{ position: "relative" }}> */}
      {submitData.length ? (
        <AccountItemList>
          {submitData.map((el, idx) => {
            return (
              <AccountItem
                key={idx}
                bottomLine={submitData.length - 1 === idx}
                submitData={el}
                setSubmitData={setSubmitData}
              />
            );
          })}
        </AccountItemList>
      ) : (
        <EmptyBox>
          <img src={EmptyPng} alt="리스트 공백" width={78} />
          <p>정산할 항목을 체크하세요</p>
        </EmptyBox>
      )}
      <ButtonContainer>
        <Button
          $isDone={submitData.length > 0}
          disabled={submitData.length <= 0}
          onClick={onSubmit}
        >
          일괄정산
        </Button>
      </ButtonContainer>
    </Container>
  );
}

const AccountItem = ({ submitData, setSubmitData, bottomLine }) => {
  const { content, nickname, paid_at, price } = submitData;
  const onDelete = () => {
    setSubmitData((prev) => prev.filter((item) => item.content !== content));
  };

  return (
    <Box>
      <p className="supplementDate">{paid_at}</p>
      <div
        className="container row"
        style={{ borderBottomWidth: bottomLine ? 0 : 1 }}
      >
        <p className="nickname">{nickname}</p>
        <p className="amount">{price?.toLocaleString() || 0}원</p>
        <span className="icon" onClick={onDelete}>
          <XIcon />
        </span>
      </div>
    </Box>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: sticky;
  top: 180px;
  padding: 0 24px;
  margin-left: 40px;
  height: 70vh;
  max-height: 720px;
  width: 430px;
  border: 1px solid ${({ theme }) => theme.colors.blueGray200};
  border-radius: 20px;
  background: #fff;
`;

const AccountItemList = styled.ul`
  height: 100%;
  overflow-y: scroll;
`;

const ButtonContainer = styled.div`
  display: flex;
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  justify-content: center;
  padding: 33px 0;
  background-color: white;
  border-radius: 20px;
`;

const Button = styled.button<{ $isDone: boolean }>`
  display: flex;
  margin: 0 auto;
  padding: 10px 72px;
  width: fit-content;
  border-radius: 8px;
  border: none;
  background: ${({ theme, $isDone }) =>
    $isDone ? theme.colors.primaryBlue100 : theme.colors.blueGray100};
  cursor: pointer;

  color: ${({ theme, $isDone }) =>
    $isDone ? "white" : theme.colors.blueGray500};
  font-size: 18px;
  font-weight: 500;
  line-height: 28px; /* 140% */
  letter-spacing: -0.4px;

  &:disabled {
    cursor: not-allowed;
  }
`;

const Box = styled.div`
  margin-top: 32px;
  .supplementDate {
    color: ${({ theme }) => theme.colors.blueGray600};
    text-align: right;
    font-feature-settings: "clig" off, "liga" off;
    font-family: Pretendard;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
    letter-spacing: -0.32px;
  }

  .container {
    margin-top: 4px;
    padding-bottom: 20px;
    border-bottom: 1px solid ${({ theme }) => theme.colors.blueGray100};
    color: ${({ theme }) => theme.colors.blueGray800};

    .nickname {
      flex-grow: 1;
      font-size: 18px;
      font-weight: 500;
      line-height: 26px; /* 144.444% */
      letter-spacing: -0.36px;
    }

    .amount {
      margin-right: 12px;
      font-size: 24px;
      font-weight: 500;
      line-height: 32px; /* 133.333% */
      letter-spacing: -0.48px;
    }

    .icon {
      cursor: pointer;
    }
  }

  .row {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;

const EmptyBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: auto 0;

  p {
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px; /* 144.444% */
    letter-spacing: -0.36px;
    color: ${({ theme }) => theme.colors.blueGray500};
  }
`;
